import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import DashboardComponents from "../../components/DashboardComponents";
import Navbar from "../../components/Navbar";
import axios from "../../axios";
import { toast } from "react-hot-toast";
import { setUserValue } from "../../redux/slices/auth";

const Dashboard = () => {
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("Admin_Token");

  useEffect(() => {
    const Api = async () => {
      if (userToken !== null) {
        const { data } = await axios.get("/superAdmin-Details", {
          headers: {
            Authorization: userToken,
          },
        });

        return dispatch(setUserValue(data.data[0]));
      } else {
        return toast.error(`Token is missing`);
      }
    };

    Api();
  }, []);

  return (
    <div className="flex flex-col bg-[#FAFAFA]">
      <Navbar />
      <DashboardComponents />
    </div>
  );
};

export default Dashboard;
