/* eslint-disable */
import React from "react";
import Navbar from "../../components/Navbar";
import { IoCallOutline, IoCardOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { BiLogIn } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { useState } from "react";
import Popup from "../../components/popUp/Popup";
import toast, { Toaster } from "react-hot-toast";
import { BsChevronDown } from "react-icons/bs";
import EventName from "./../../components/popUp/EventName";
import DateFilterMenu from "./../../components/DateFilterMenu";
import { useEffect } from "react";
import axios from "../../axios";
import ReactPaginate from "react-paginate";
import { PuffLoader } from "react-spinners";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

const Payment = () => {
  const mood = "light";
  const [viewDetails, setViewDetails] = useState(false);
  const [sideOption, setSideOption] = useState(false);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [selectStatus, setStatus] = useState(false);
  const [search, setSearch] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOpen2, setFilterOpen2] = useState(false);
  const [allUser, setallUser] = useState([]);
  const [emailSelectOption, setEmailSelectOption] = useState("Export");
  const [filterDatas, setfilterDatas] = useState([]);
  const [dateType, setDateType] = useState("All");
  const [dateRange, setDateRange] = useState("");
  const [infoStatus, setinfoStatus] = useState(null);
  const [id, setId] = useState("");
  const [popupName, setpopupName] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [getData, setgetData] = useState(false);
  const [userStatus, setuserStatus] = useState(false);

  const csvExport = [
    { label: "NAME", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone_no", key: "phone_no" },
    { label: "Date", key: "date" },
    { label: "TRANSACTION_ID", key: "transcation_id" },
    { label: "PAYMENT_METHOD", key: "feedback" },
    { label: "SUBSCRIPTION", key: "payment_plan" },
    { label: "AMOUNT", key: "amount" },
  ];

  document.body.addEventListener("click", () => {
    setStatus(false);
  });

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allUser?.length;

    setItemOffset(newOffset);
  };

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterDatas?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allUser?.length / itemsPerPage);

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems?.map((i, index) => {
            console.log('ii', i);
            return (
              <tr key={index} className="bg-grayLigth text-left">
                <td class="px-4 py-4 text-start text-sm font-bold text-gray-900 whitespace-nowrap">
                  <span class="inline-flex items-center">
                    <img
                      class="flex-shrink-0 object-cover w-8 h-8 mr-3 rounded-full"
                      src={
                        i?.profilePic?.length === 0
                          ? "https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/table-list/1/clarity-ecommerce-logo.png"
                          : i?.profilePic
                      }
                      alt=""
                    />
                    {i.name}
                  </span>
                </td>
                <td class="px-4 py-4 text-start text-sm font-bold text-[#71717A] whitespace-nowrap">
                  <span class="inline-flex gap-1 items-center">
                    <HiOutlineMail />
                    {i.email}
                  </span>
                </td>
                <td class="px-4 py-4 text-start text-sm font-bold text-[#71717A] whitespace-nowrap">
                  <span class="inline-flex gap-1 items-center">
                    <IoCallOutline />
                    {i.phone_no}
                  </span>
                </td>

                <td className=" px-4 py-4  text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                  <div
                    className={`flex items-center justify-start font-inter text-[13px] ${
                      mood === "light" ? "text-[#71717A]" : "text-gray"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                      />
                    </svg>
                    <p>
                      {dayjs(i?.date).format("DD/MM/YYYY")}{" "}
                      <span>{dayjs(i?.date).format("hh:mm A")}</span>
                    </p>
                  </div>
                </td>

                <td className=" px-4 py-4 text-start text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                  <div
                    className={`flex items-center justify-start  font-inter text-[13px] ${
                      mood === "light" ? "text-[#71717A]" : "text-gray"
                    }`}
                  >
                    {i.transcation_id}
                  </div>
                </td>

                <td className=" px-4 py-4 text-start text-sm font-medium text-grayDark xl:table-cell whitespace-nowrap">
                  <div
                    className={`flex items-center justify-start font-inter text-[13px] ${
                      mood === "light" ? "text-[#71717A]" : "text-gray"
                    }`}
                  >
                    <IoCardOutline size={25} className=" text-blue mx-3" />
                    {i.payment_mode}
                  </div>
                </td>

                <td className=" px-4 py-4 text-start text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                  <span
                    className={`${
                      i.plan === "Premium"
                        ? "bg-[#FFE4E6] text-[#BE123C]"
                        : "bg-[#A7F3D0] text-[#065F46]"
                    } px-4 py-1 rounded-full`}
                  >
                    Premium
                  </span>
                </td>

                <td className=" px-4 py-4 text-start text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                  <span
                    className={`${
                      i.payment_plan === "year"
                        ? "bg-[#FEF9C3] text-[#854D0E]"
                        : i.sub === "month"
                        ? "bg-[#DDD6FE] text-[#5B21B6]"
                        : "bg-[#E4E4E7] text-[#27272A]"
                    } px-4 py-1 rounded-full`}
                  >
                    {i.payment_plan}
                  </span>
                </td>

                <td className=" px-4 py-4 text-start text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap">
                  <span className="bg-[#EEF2FF] text-[#4F46E5] px-4 py-1 rounded-full">
                    ₹{i.amount}
                  </span>
                </td>
              </tr>
            );
          })}
      </>
    );
  }

  useEffect(() => {
    setinfoStatus(true);
    const API = async () => {
      if (dateType === "dateRange") {
        const { data } = await axios.post("/get-all/payments", {
          label: dateType,
          fromDate: dateRange[0],
          toDate: dateRange[1],
        });

        if (data.success) {
          setinfoStatus(false);
          return setallUser(data.data);
        }
      } else {
        const users = await axios.post("/get-all/payments", {
          label: dateType === "All" ? "" : dateType,
          fromDate: "",
          toDate: "",
        });

        if (users.data.success) {
          setinfoStatus(false);
          return setallUser(users.data.data);
        }
      }
    };
    API();
  }, [getData, dateType, dateRange]);

  useEffect(() => {
    const API = async () => {
      if (userStatus) {
        const { data } = await axios.post("/get-all/payments", {
          label: dateType === "All" ? "" : dateType,
          fromDate: "",
          toDate: "",
        });

        if (data.success) {
          setallUser(data.data);
          setuserStatus(false);
          return;
        }
      }
    };
    API();
  }, [userStatus]);

  useEffect(() => {
    const result =
      search === ""
        ? allUser
        : allUser.filter(
            (item) =>
              item?.name?.toLowerCase().includes(search?.toLowerCase()) ||
              item?.email?.toLowerCase().includes(search?.toLowerCase())
          );
    return setfilterDatas(result);
  }, [search, allUser]);

  return (
    <div onClick={() => setViewDetails(false)}>
      <Navbar />

      <>
        <div
          className={`${
            mood === "light" ? `bg-[#fafafa]` : "bg-[#161A25]"
          } lg:py-10`}
          onClick={() => setSideOption(false)}
        >
          <div className="px-4 mx-auto w-[95%] sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div>
                <p
                  className={`text-xl font-bold ${
                    mood === "light" ? "text-[#18181B]" : "text-gray"
                  }`}
                >
                  Payments
                </p>
                <p className={`mt-1 text-sm font-medium  text-[#71717A]`}>
                  Lorem ipsum dolor sit amet, consectetur adipis.{" "}
                </p>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[300px] rounded-lg  border-[#E4E4E7] border-2 flex items-center overflow-hidden">
                  <AiOutlineSearch color="#9e9e9f" className="w-6 h-6 mx-2 " />

                  <input
                    type="search"
                    placeholder="Search"
                    className="outline-0 border-0 flex-1 py-2 rounded-lg px-1"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>

                <div className="relative min-w-[150px]">
                  <button
                    onClick={() => {
                      setFilterOpen2((prev) => !prev);
                      setFilterOpen(false);
                    }}
                    type="button"
                    className="relative w-full cursor-default rounded-md bg-white py-2 px-4 text-left text-grayDark shadow-sm ring-1 ring-inset ring-gray focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  >
                    <span className="flex items-center">
                      <span className="block truncate">Export</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <BsChevronDown size={16} />
                    </span>
                  </button>

                  <ul
                    className={` ${
                      filterOpen2 ? "visible" : "hidden"
                    } absolute mt-1 max-h-56 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                    tabindex="-1"
                    role="listbox"
                  >
                    {/* <li
                      onClick={() => {
                        setEmailSelectOption("pdf");
                        setFilterOpen2(false);
                      }}
                      className="text-gray-900 hover:bg-grayLight relative select-none py-2 pl-3 pr-9 cursor-pointer"
                      role="option"
                    >
                      <div className="flex items-center">
                        <span className="font-normal ml-3 block truncate">
                          Export Pdf
                        </span>
                      </div>
                    </li> */}
                    <li
                      onClick={() => {
                        setEmailSelectOption("csv");
                        setFilterOpen2(false);
                      }}
                      className="text-gray-900 hover:bg-grayLight relative select-none py-2 pl-3 pr-9 cursor-pointer"
                      role="option"
                    >
                      <div className="flex items-center">
                        <CSVLink
                          data={filterDatas.map((i) => i)}
                          headers={csvExport}
                          filename="csvDownload"
                          target="_blank"
                        >
                          <span className="font-normal ml-3 block truncate">
                            Export to CSV
                          </span>
                        </CSVLink>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* </CSVLink> */}

                <div className="relative inline-block text-left">
                  <div>
                    <button
                      onClick={() => {
                        setFilterOpen((prev) => !prev);
                        setFilterOpen2(false);
                      }}
                      type="button"
                      className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white py-2.5 px-4 text-left text-grayDark text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray hover:bg-gray-50"
                    >
                      {dateType}
                      <BsChevronDown size={16} />
                    </button>
                  </div>

                  {filterOpen && (
                    <DateFilterMenu
                      filterOpen={filterOpen}
                      close={setFilterOpen}
                      setDateTypes={setDateType}
                      dateRanges={setDateRange}
                      getData={setgetData}
                      userManagement="All"
                    />
                  )}
                </div>
              </div>
            </div>

            {infoStatus && (
              <>
                <div
                  className={`w-[90%] h-[60%] absolute flex items-center justify-center ${
                    mood === "light" ? "bg-[#fafafa]" : "bg-[#161A25]"
                  } `}
                >
                  <PuffLoader size={200} color="#4F46E5" />
                </div>
              </>
            )}

            {!infoStatus && (
              <div className="flex flex-col mt-4 lg:mt-8">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 no-scrollbar">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6  lg:px-8 rounded-xl">
                    <table
                      style={{
                        boxShadow: "0px 2.37037px 8px rgba(0, 0, 0, 0.06)",
                      }}
                      className={`border border-[#E4E4E7] min-w-full lg:divide-gray lg:divide-y ${
                        mood === "light"
                          ? "bg-white"
                          : "bg-[#161A25] shadow-[#303544]"
                      } rounded-lg mb-[50px] mt-[20px] overflow-auto`}
                    >
                      <thead className="lg:table-header-group">
                        <tr>
                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Full NAME
                          </th>
                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Email Address
                          </th>

                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Phone Number
                          </th>

                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Date & Time
                          </th>

                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Transaction id
                          </th>
                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Payment Method
                          </th>

                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Plan
                          </th>

                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Subscription
                          </th>
                          <th
                            className={`py-3.5 px-4 text-start text-[12px] font-[600] ${
                              mood === "light" ? "text-[#71717A]" : "text-gray"
                            } uppercase`}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        className="text-center divide-gray divide-y"
                        onClick={() =>
                          sideOption === true && setSideOption(false)
                        }
                      >
                        <Items currentItems={currentItems} />
                      </tbody>
                    </table>
                    <div className="flex w-full justify-end -mt-6">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>

      {viewDetails && <Popup show={true} hide={setViewDetails} id={id} />}

      <Toaster position="top-right" reverseOrder={false} />
      {showPopup && (
        <EventName
          show={showPopup}
          hide={setShowPopup}
          status={popupName}
          id={id}
          trigger={setuserStatus}
          getTrigger={userStatus}
        />
      )}
    </div>
  );
};

export default Payment;
