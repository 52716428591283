/* eslint-disable  */

import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { BsChevronDown } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import "flatpickr/dist/themes/material_green.css";
import DateFilterMenu from "./../DateFilterMenu";
import axios from "../../axios";

const FeedbacksChart = () => {
  const [data, setData] = useState([]);
  const [label, setlabel] = useState("today");
  const [dateType, setDateType] = useState("today");
  const [categories, setcategories] = useState([]);
  const [getData, setgetData] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOpen2, setFilterOpen2] = useState(false);
  const [search, setSearch] = useState("");
  const [eventID, setEventID] = useState("");
  const [eventList, seteventList] = useState([]);
  const [filterValue, setfilterValue] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("All Users");
  const [length, setlength] = useState(0);
  const [totalCount, settotalCount] = useState(0);

  useEffect(() => {
    const getData = async () => {
      if (dateType === "dateRange") {
        if (dateRange !== null) {
          const { data } = await axios.post("/userpaymentChart", {
            label: dateType,
            fromDate: dateRange[0],
            toDate: dateRange[1],
            eventID: eventID,
          });
          setlabel(
            (dateType === "dateRange" && "date") ||
              (dateType === "today" && "today") ||
              (dateType === "yesterday" && "today") ||
              (dateType === "thisMont" && "month") ||
              (dateType === "lastMonth" && "month") ||
              (dateType === "thisWeek" && "week") ||
              (dateType === "lastWeek" && "week") ||
              (dateType === "thisYear" && "year") ||
              (dateType === "lastYear" && "year")
          );
          setData(data);
          setgetData(false);
          setDateRange(null);
          const startDate = new Date(dateRange[0]);
          const endDate = new Date(dateRange[1]);
          const dateRanges = Math.round(
            (endDate - startDate) / (1000 * 60 * 60 * 24)
          );
          setlength(dateRanges);
          const sum = data.reduce((acc, val) => acc + val, 0);
          settotalCount(sum);
        }
      } else {
        const { data } = await axios.post("/userpaymentChart", {
          label: dateType,
          eventID: eventID,
        });
        setlabel(
          (dateType === "today" && "today") ||
            (dateType === "yesterday" && "today") ||
            (dateType === "thisMonth" && "month") ||
            (dateType === "lastMonth" && "month") ||
            (dateType === "thisWeek" && "week") ||
            (dateType === "lastWeek" && "week") ||
            (dateType === "thisYear" && "year") ||
            (dateType === "lastYear" && "year")
        );
        setData(data);
        setgetData(false);
        const sum = data.reduce((acc, val) => acc + val, 0);
        settotalCount(sum);
      }
    };
    getData();
  }, [getData, dateType, dateRange]);

  useEffect(() => {
    const getAllEvent = async () => {
      const { data } = await axios.get("/getallUsers");

      if (data.success) {
        return seteventList(data.data);
      }
    };
    getAllEvent();
  }, []);

  useEffect(() => {
    const result =
      search?.length === 0
        ? eventList
        : eventList.filter((item) =>
         item.name?.toLowerCase().match(search)
          );

    setfilterValue(result);
  }, [search, eventList]);

  useEffect(() => {
    switch (label) {
      case "month":
        setcategories(Array.from({ length: 31 }, (_, i) => (i + 1).toString()));
        break;

      case "date":
        setcategories(
          Array.from({ length: length }, (_, i) => (i + 1).toString())
        );
        break;

      case "week":
        setcategories([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ]);
        break;
      case "year":
        setcategories([
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]);
        break;
      case "today":
        setcategories([
          "12AM",
          "1AM",
          "2AM",
          "3AM",
          "4AM",
          "5AM",
          "6AM",
          "7AM",
          "8AM",
          "9AM",
          "10AM",
          "11AM",
          "12PM",
          "1PM",
          "2PM",
          "3PM",
          "4PM",
          "5PM",
          "6PM",
          "7PM",
          "8PM",
          "9PM",
          "10PM",
          "11PM",
        ]);
        break;
    }
  }, [label]);

  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 18,
        left: 2,
        blur: 5,
        opacity: 0.1,
      },
    },
    markers: {
      colors: "#4F46E5",
      strokeColors: "#4F46E5",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: "#4F46E5",
    },
    title: {
      text: `${
        dateType === "dateRange" ? "" : dateType.toUpperCase()
      } Total Feedback Count: ${totalCount}`,
      align: "left",
      offsetX: -10,
      style: {
        fontSize: "20px",
        fontWeight: "500",
        color: "#737373",
      },
    },
    grid: {
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categories,
    },
    noData: {
      text: "Loading...",
    },
  };

  // ** Chart Series
  const series = [
    {
      name: "Feedbacks",
      data: data,
    },
  ];

  return (
    <div>
      <div
        style={{ boxShadow: "0px 2.37037px 8px rgba(0, 0, 0, 0.06)" }}
        className="border border-[#E4E4E7] max-h-[500px] h-[500px] overflow-hidden bg-white rounded-xl"
      >
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-[20px] font-semibold text-[#737373] lg:order-1">
                Feedbacks
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <div className="mt-4 sm:mt-0">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      onClick={() => {
                        setFilterOpen(false);
                        setFilterOpen2((prev) => !prev);
                      }}
                      type="button"
                      className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {selectedEvent}
                      <BsChevronDown size={16} />
                    </button>
                  </div>

                  <div
                    className={` ${
                      filterOpen2 ? "visible" : "hidden"
                    } absolute p-4 right-0 z-10 mt-2 w-[400px] h-[400px] overflow-hidden origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div className="flex items-center justify-between  cursor-pointer">
                      <label
                        for="feedback-checkbox-all"
                        className="ml-2 w-full text-base select-none font-medium text-[#436073] cursor-pointer dark:text-gray-300"
                        onClick={() => {
                          setEventID("");
                          setSelectedEvent("All users");
                          setFilterOpen2(false);
                        }}
                      >
                        All users
                      </label>
                    </div>

                    <hr className=" my-4 text-gray" />

                    <div className="w-full rounded-lg  border-[#E4E4E7] border-2 flex items-center overflow-hidden">
                      <input
                        value={search}
                        type="search"
                        placeholder="Search"
                        className="outline-0 border-0 flex-1 py-2 rounded-lg px-2 placeholder:text-[#4F46E5]"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <AiOutlineSearch
                        color="#4F46E5"
                        className="w-6 h-6 mx-2 "
                      />
                    </div>

                    {/* <p className="my-4 text-sm text-gray">
                      {search.length ? "Search Result" : ""}
                    </p> */}

                    <ul className="flex flex-col gap-4 w-[100%] h-[70%] mt-5 overflow-auto bg-white">
                      {filterValue.map((i, index) => {
                        return (
                          <li
                            key={index}
                            className="flex items-center justify-between  cursor-pointer"
                          >
                            <label
                              for={`feedback-checkbox-item-${index}`}
                              className="ml-2 w-full text-base select-none font-medium text-[#436073] cursor-pointer"
                              onClick={() => {
                                setEventID(i.id);
                                setSelectedEvent(i.name);
                                setFilterOpen2(false);
                              }}
                            >
                              {i.name}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/* date filter */}
              <div className="mt-4 sm:mt-0">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      onClick={() => {
                        setFilterOpen((prev) => !prev);
                        setFilterOpen2(false);
                      }}
                      type="button"
                      className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {dateType}
                      <BsChevronDown size={16} />
                    </button>
                  </div>

                  {filterOpen && (
                    <DateFilterMenu
                      filterOpen={filterOpen}
                      close={setFilterOpen}
                      setDateTypes={setDateType}
                      dateRanges={setDateRange}
                      getData={setgetData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <ReactApexChart options={options} series={series} height={400} />
        </div>
      </div>
    </div>
  );
};

export default FeedbacksChart;
