/* eslint-disable */

import React, { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import feedbacklogoDark from "../../assets/logo/logo.svg";
import EventName from "../popUp/EventName";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiChevronDown, BiX } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { CiUser, CiCreditCard1 } from "react-icons/ci";
import { BsBell } from "react-icons/bs";
import DateFilterMenu from "./../DateFilterMenu";
import { useSelector } from "react-redux";
import axios from "../../axios";

const Navbar = ({ popups, inputValue, setNames, hide }) => {
  const { user } = useSelector((state) => state.userAuth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [notificationList, setnotificationList] = useState([]);
  const [notificationCount, setnotificationCount] = useState(0);
  const [dateType, setDateType] = useState("All");
  const [dateRange, setDateRange] = useState("");
  const [getData, setgetData] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    const mainPath = path.substring(1);
    if (mainPath.length == 0) {
      setSelected("dashboard");
    } else {
      setSelected(mainPath);
    }
  }, [location.pathname]);

  useEffect(() => {
    const API = async () => {
      if (dateType === "dateRange") {
        const { data } = await axios.post("/get-Notification-sa", {
          label: dateType,
          fromDate: dateRange[0],
          toDate: dateRange[1],
        });

        if (data.success) {
          const filterCount = data.data.filter(
            (i) => i.notification_open !== true
          );
          setnotificationCount(filterCount.length);
          setnotificationList(data.data);
        }
      } else {
        const getNotification = await axios.post("/get-Notification-sa", {
          label: dateType,
        });

        if (getNotification.data.success) {
          const filterCount = getNotification.data.data.filter(
            (i) => i.notification_open !== true
          );
          setnotificationCount(filterCount.length);
          setnotificationList(getNotification.data.data);
        }
      }
    };
    API();
  }, [getData, dateType, dateRange, sidebarOpen]);

  const handelClick = async (id) => {
    const { data } = await axios.post("/open-notification-sa", {
      id,
    });
    if (data.success) {
      if (dateType === "dateRange") {
        const datas = await axios.post("/get-Notification-sa", {
          label: dateType,
          fromDate: dateRange[0],
          toDate: dateRange[1],
        });

        if (datas.data.success) {
          const filterCount = datas.data.data.filter(
            (i) => i.notification_open !== true
          );
          setnotificationCount(filterCount.length);
          setnotificationList(datas.data.data);
        }
      } else {
        const getNotification = await axios.post("/get-Notification-sa", {
          label: dateType,
        });

        if (getNotification.data.success) {
          const filterCount = getNotification.data.data.filter(
            (i) => i.notification_open !== true
          );
          setnotificationCount(filterCount.length);
          setnotificationList(getNotification.data.data);
        }
      }
    }
  };

  return (
    <header className=" sticky -top-[80px] z-[999]">
      <div className={`py-3 border-b bg-black border-[#E4E4E7]`}>
        <div className={`px-4 mx-auto  w-[95%] sm:px-2 py-1`}>
          <div className="flex items-center w-full xs:flex-col sm:flex-col ms:flex-col lg:flex-row xl:flex-row">
            <div className="my-2">
              <Link
                // to={lock !== true && "/"}
                title=""
                className="flex items-center"
              >
                <>
                  <img
                    className="block w-auto h-8 lg:hidden"
                    src={feedbacklogoDark}
                    alt=""
                  />
                  <img
                    className="hidden w-auto h-8 lg:block"
                    src={feedbacklogoDark}
                    alt=""
                  />
                </>
              </Link>
            </div>

            <div className="flex-1">
              <div className="flex lg:justify-end xl:justify-end lg:w-full xl:w-full xs:w-screen sm:w-screen ms:w-screen sm:justify-between ms:justify-between xs:justify-between">
                <div
                  onClick={() => setSidebarOpen(true)}
                  className="relative cursor-pointer"
                >
                  <BsBell size={22} className="text-white mr-6" />
                  <div className="absolute text-white bg-blue w-4 h-4 rounded-full text-xs top-0 right-5 grid place-items-center">
                    {notificationCount}
                  </div>
                </div>
                <button
                  type="button"
                  className={`rounded-full `}
                  id="options-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setPopupStatus(!popupStatus);

                    // if (location.pathname !== "/addQuestions") {
                    //   setPopupStatus(!popupStatus);
                    // }
                    // if (location.pathname === "/addQuestions") {
                    // return toast.error(
                    //   "You are unable to enter addQuestions."
                    // );
                    // }
                  }}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center justify-between min-w-0 space-x-3 relative">
                      <img
                        className="flex-shrink-0 object-cover bg-gray rounded-full w-7 h-7"
                        src={
                          user?.profilePic === ""
                            ? "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                            : user?.profilePic
                        }
                        alt=""
                      />
                      <p className="text-sm font-medium  text-white">
                        {user?.name}
                      </p>
                      {popupStatus && (
                        <div
                          style={{
                            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                          }}
                          className="absolute z-[999] rounded w-40 py-3 top-[40px]  text-start -right-10 -mt-1 transition-all duration-500 px-3 font-inter bg-white text-black"
                        >
                          <Link
                            to="/account"
                            // onClick={() => {
                            //     if (location.pathname === "/addQuestions") {
                            //         setPop(true)
                            //         setpayment("account")
                            //     } else {
                            //         window.location.replace("/account")
                            //     }
                            // }}
                          >
                            <p className="px-3 rounded-sm hover:bg-[#ddd] hover:text-black py-1.5 font-[500] capitalize cursor-pointer font-inter !text-start">
                              My Account
                            </p>
                          </Link>
                          <p
                            onClick={() => setLogoutPopup(true)}
                            className="px-3 rounded-sm hover:bg-[#ddd] py-1.5 font-[500] hover:text-black capitalize cursor-pointer font-inter !text-start"
                          >
                            Log out
                          </p>
                        </div>
                      )}
                    </div>
                    <BiChevronDown
                      size={25}
                      className="flex-shrink-0 ml-2 text-gray group-hover:text-gray"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="hidden py-2 bg-white border-[#E4E4E7] border-b lg:block">
        <div className="px-4 mx-auto w-[95%] 3xl:max-w-7xl sm:px-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Link to={"/"}>
                <div
                  className={`cursor-pointer inline-flex items-center px-3 py-3 text-sm font-medium transition-all duration-200 bg-transparent rounded-lg font-inter hover:bg-[#4e46e52d] hover:text-[#4F46E5] text-[#18181B] ${
                    selected === "dashboard" &&
                    `!text-[#4F46E5] !bg-[#4e46e52d]`
                  }`}
                >
                  <AiOutlineHome size={20} className={`mx-2`} />
                 <span className="mt-1">Dashboard</span> 
                </div>
              </Link>

              <Link to={"/user-management"}>
                <div
                  className={`cursor-pointer inline-flex items-center px-3 py-3 text-sm font-medium transition-all duration-200 bg-transparent rounded-lg font-inter hover:bg-[#4e46e52d] hover:text-[#4F46E5] text-[#18181B]  ${
                    selected === "user-management" &&
                    "!text-[#4F46E5] !bg-[#4e46e52d]"
                  }`}
                >
                  <AiOutlineUser size={20} className={`mx-2`} />
                  <span className="mt-1">User Management</span>
                  
                </div>
              </Link>

              <Link to={"/payment"}>
                <div
                  className={`cursor-pointer inline-flex items-center px-3 py-3 text-sm font-medium transition-all duration-200 bg-transparent rounded-lg font-inter hover:bg-[#4e46e52d] hover:text-[#4F46E5] text-[#18181B]  ${
                    selected === "payment" && "!text-[#4F46E5] !bg-[#4e46e52d]"
                  }`}
                >
                  <CiCreditCard1 size={20} className={`mx-2`} />
                  <span className="mt-1">Payment</span>
                  
                </div>
              </Link>
              {/* <Link to={"/global-settings"}>
                <div
                  className={`cursor-pointer inline-flex items-center px-3 py-3 text-sm font-medium transition-all duration-200 bg-transparent rounded-lg font-inter hover:bg-[#4e46e52d] hover:text-[#4F46E5] text-[#18181B]  ${
                    selected === "global-settings" &&
                    "!text-[#4F46E5] !bg-[#4e46e52d]"
                  }`}
                >
                  <HiOutlineCog6Tooth size={20} className={`mx-2`} />
                  Global Settings
                </div>
              </Link> */}
            </div>
          </div>
        </div>
      </nav>

      <aside
        className={` w-[450px] fixed top-0 ${
          sidebarOpen ? "right-0" : "right-[-450px]"
        } bg-white h-screen shadow-lg p-8 transition-all duration-500 z-[999]`}
      >
        <div className="flex justify-between items-center">
          <div
            onClick={() => setFilterOpen((prev) => !prev)}
            className="grid items-center bg-blue rounded-full p-2 cursor-pointer"
          >
            <FiFilter size={24} className="text-white" />
          </div>
          <div className="absolute top-5 left-4">
            {filterOpen && (
              <DateFilterMenu
                filterOpen={filterOpen}
                close={setFilterOpen}
                setDateTypes={setDateType}
                dateRanges={setDateRange}
                getData={setgetData}
                userManagement="All"
              />
            )}
          </div>
          <h1 className=" text-2xl font-bold select-none">Notification</h1>
          <div
            onClick={() => {
              setDateType("All");
              setDateRange("");
              setgetData(false);
              setSidebarOpen(false);
            }}
            className="grid items-center bg-cl rounded-full p-2 cursor-pointer"
          >
            <BiX size={24} className="text-white" />
          </div>
        </div>

        <div className=" overflow-y-auto p-3 h-[93%] mt-10">
          <div className="flex flex-col gap-4 mt-10 ">
            {/* dummy array */}
            {notificationList.map((i, index) => (
              <div
                onClick={() => handelClick(i.id)}
                className={`flex px-4 py-2 border border-[#E4E4E7] rounded-lg items-center gap-3 cursor-pointer ${
                  i.notification_open ? "bg-transparent" : "bg-[#4F46E526]"
                } `}
                key={index}
              >
                <div className=" bg-[rgba(217,217,217,0.20)] w-[60px] h-[60px] flex items-center justify-center bg-grayLight rounded-full overflow-hidden ">
                  {i.picture === "" ? (
                    <AiOutlineUser size={20} className="text-blue" />
                  ) : (
                    <img className="" src={i.picture} alt="pic" />
                  )}
                </div>
                <div>
                  <h4 className=" text-base font-medium">{i.message}</h4>
                  <p className="text-sm font-medium text-[#595C76] mt-2">
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    }).format(new Date(i.createdAt))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </aside>

      {logoutPopup && (
        <EventName status="logout" show={logoutPopup} hide={setLogoutPopup} />
      )}
    </header>
  );
};

export default Navbar;
