/* eslint-disable */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { setEventValue } from "../../redux/slices/eventName";
import axios from "../../axios";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import failed from "../../assets/failed.png";
// import {
//   bgImageDetails,
//   logoDetails,
//   questionEmpty,
// } from "../../redux/slices/questions";
import { AiOutlineClose } from "react-icons/ai";
import { MdContentCopy, MdQrCodeScanner } from "react-icons/md";
import { BsEmojiSmile, BsEmojiSmileFill, BsLink45Deg } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
// import QRcode from "../qr-code";
import Emoji from "react-emojis";
import { logOut } from "../../redux/slices/auth";
import { PuffLoader } from "react-spinners";

// import { remove } from "../../redux/slices/feedbackValue";
// const { v4: uuidv4 } = require("uuid");

const EventName = ({
  show,
  hide,
  status,
  message,
  trigger,
  getTrigger,
  id,
}) => {
  const navigate = useNavigate();
  // const { eventName } = useSelector((state) => state.eventName)
  // const { mood } = useSelector((state) => state.mood)
  const mood = "light";
  const dispatch = useDispatch();
  const close = () => {
    return hide(false);
  };
  const [loader, setloader] = useState(false);
  // const [name, setName] = useState("")
  // const [download, setdownload] = useState(false)

  // const Submit = () => {
  //     dispatch(setEventValue(name))
  // }
  // const addEvent = () => navigate("/addQuestions")

  // useEffect(() => {}, [eventName])

  // const active = async () => {
  //     const RandomNumber = `${Math.floor(1000 + Math.random() * 9000)}`
  //     const { data } = await axios.post("/edit/event", {
  //         id: id
  //     })
  //     if (data.success) {
  //         toast.success(`${data.message}`)
  //         changeId(id)
  //         setActionStatus(!toogle)
  //         trigger(RandomNumber)

  //         return
  //     }
  // }
  // const deleteEvent = async (i) => {
  //     const { data } = await axios.post("/delete/event", {
  //         id: i
  //     })
  //     if (data.success) {
  //         toast.success(data.message)
  //         trigger(i)
  //         close()
  //         return
  //     }
  // }

  // const clear = () => {
  //     dispatch(logoDetails([]))
  //     dispatch(bgImageDetails([]))
  //     dispatch(questionEmpty())
  //     dispatch(setEventValue("New Event"))
  //     navigate("/events")
  // }

  const clearAll = async () => {
    close();
    dispatch(logOut());
    navigate("/login");
  };

  const handelDeactivateUser = async (i) => {
    setloader(true);
    const { data } = await axios.post("/deactivate-useraccount", {
      id: i,
    });
    if (data.success) {
      trigger(true);
      close();
      return;
    }
  };

  const handelActivateUser = async (i) => {
    setloader(true);
    const { data } = await axios.post("/activate-useraccount", {
      id: i,
    });
    if (data.success) {
        trigger(true);
      close();

      return;
    }
  };

  return (
    <div>
      {show && (
        <>
          <div
            id="popup-modal"
            tabindex="-1"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
            className={`!bg-[#7f838f67] fixed flex items-center top-0 left-0 justify-center z-50 right-auto w-[100vw] h-[100vh]`}
            aria-hidden="true"
          >
            <div className="relative w-full flex items-center justify-center">
              <div
                className={`bg-white p-4 relative rounded-lg shadow-2xl w-[420px]`}
              >
                {/* {list && (
                                    <>
                                        <div className="p-6 text-center justify-center flex items-center flex-col">
                                            <button
                                                type="button"
                                                className="absolute -top-2 -right-2 w-6 h-6 text-white bg-red rounded-full text-sm p-1 ml-auto inline-flex items-center justify-center  transition-all duration-300"
                                                data-modal-toggle="popup-modal"
                                            >
                                                {condition === "open" ? (
                                                    <a href={condition === "open" && "https://www.feedbacksync.com/"}>
                                                        <AiOutlineClose />
                                                    </a>
                                                ) : (
                                                    <AiOutlineClose />
                                                )}
                                            </button>
                                            <div className="px-3 text-center flex items-center flex-col">

                                                <Emoji
                                                    emoji="face-with-open-mouth"
                                                    size={60}
                                                />
                                                <div className="my-3 px-3 mt-3">
                                                    <p className="text-sm text-[#18181B] py-4">
                                                        Event Disabled, Please contact Administrator
                                                    </p>
                                                    <button
                                                        className={` focus:ring-4 focus:outline-none border !mx-auto px-8 block justify-center items-centermax-w-[45%] border-[lightBlue] text-white hover:!text-white bg-lightBlue hover:bg-lightBlue font-medium rounded text-sm py-2 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue`}
                                                    
                                                    >
                                                        {condition === "open" ? (
                                                            <a
                                                                href={
                                                                    condition === "open" &&
                                                                    "https://www.feedbacksync.com/"
                                                                }
                                                            >
                                                                Close
                                                            </a>
                                                        ) : (
                                                            "Close"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )} */}
                {/* {status === "addEvent" && (
                                    <>
                                        <div
                                            className="p-6 text-center flex items-center flex-col w-full"
                                            data-tut="second-step"
                                        >
                                            <Emoji
                                                size={60}
                                                emoji="slightly-smiling-face"
                                            />

                                            <p
                                                className={`font-[600] mt-3 ${
                                                    mood === "light" ? "text-black" : "!text-white"
                                                } font-inter`}
                                            >
                                                Create Campaign
                                            </p>
                                            <div className="my-3 w-full">
                                                <input
                                                    type="text"
                                                    placeholder="Event Name"
                                                    className="p-3 !py-2.5 w-full rounded outline-none bg-[#FAFAFA] border-gray border"
                                                    onChange={(e) => {
                                                        setName(e.target.value)
                                                    }}
                                                    value={name}
                                                />
                                            </div>
                                            <div className="mt-1 flex justify-between mx-auto w-full">
                                                <button
                                                    onClick={close}
                                                    data-modal-toggle="popup-modal"
                                                    type="button"
                                                    className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border ${
                                                        mood === "light"
                                                            ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                                                            : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                                                    } text-sm font-medium py-2.5 bg-transparent transition-all duration-300 flex justify-center items-center w-[45%]`}
                                                >
                                                    cancel
                                                </button>
                                                <button
                                                    data-modal-toggle="popup-modal"
                                                    type="button"
                                                    className={`focus:ring-4 focus:outline-none border flex justify-center items-center w-[45%] border-[#4F46E5] ${
                                                        mood === "light"
                                                            ? "text-white hover:!text-white bg-[#4F46E5] hover:bg-[#4F46E5]"
                                                            : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-white"
                                                    } font-medium rounded text-sm inline-flex items-center py-2.5 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                                                    onClick={() => {
                                                        if (name !== "") {
                                                            addEvent()
                                                            setInput(name)
                                                            Submit()
                                                            close()
                                                            localStorage.setItem("uuid", uuidv4())
                                                        } else {
                                                            toast.error("Please insert a name")
                                                        }
                                                    }}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )} */}
                {/* {status === "rename" && (
                                    <>
                                        <>
                                            <div className="p-6 text-center flex items-center flex-col w-full">
                                                <Emoji
                                                    size={60}
                                                    emoji="face-with-raised-eyebrow"
                                                />
                                                <p
                                                    className={`font-[600] mt-3 ${
                                                        mood === "light" ? "text-black" : "!text-white"
                                                    } font-inter`}
                                                >
                                                    Rename Campaign
                                                </p>
                                                <div className="my-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Event Name"
                                                        className="p-3 !py-235 w-80 rounded outline-none bg-[#FAFAFA] border-gray border"
                                                        onChange={(e) => {
                                                            setInput(e.target.value)
                                                            setName(e.target.value)
                                                        }}
                                                        value={inputValue}
                                                    />
                                                </div>
                                                <div className="mt-1 flex justify-between w-full mx-auto">
                                                    <button
                                                        onClick={close}
                                                        data-modal-toggle="popup-modal"
                                                        type="button"
                                                        className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border ${
                                                            mood === "light"
                                                                ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                                                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                                                        } text-sm font-medium py-2.5 bg-transparent transition-all duration-300 flex justify-center items-center w-[45%]`}
                                                    >
                                                        cancel
                                                    </button>
                                                    <button
                                                        data-modal-toggle="popup-modal"
                                                        type="button"
                                                        className={`focus:ring-4 focus:outline-none border flex justify-center items-center w-[45%] border-[#4F46E5] ${
                                                            mood === "light"
                                                                ? "text-white hover:!text-white bg-[#4F46E5] hover:bg-[#4F46E5]"
                                                                : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-white"
                                                        } font-medium rounded text-sm inline-flex items-center py-2.5 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                                                        onClick={() => {
                                                            Submit()
                                                            close()
                                                        }}
                                                    >
                                                        Rename
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    </>
                                )} */}
                {/* 
                                {status === "created" && (
                                    <>
                                        <>
                                            <div className="p-6 text-center flex items-center flex-col w-full">
                                               
                                                <Emoji
                                                    size={60}
                                                    emoji="grinning-face-with-smiling-eyes"
                                                />
                                                <p className="font-[600] mt-3 font-inter">Success!</p>
                                                <div className="my-3">
                                                    <p className="text-sm text-[#18181B] py-4 !pt-0">
                                                        Congratulations,{" "}
                                                        <span className="text-[#4F46E5]">“{eventName}”</span> Campaign{" "}
                                                        <br />
                                                        has been Successfully Created.
                                                    </p>
                                                </div>
                                                <div className="my-3 w-full">
                                                    <div className="inpuStyle">
                                                        <div className="px-2">
                                                            <BsLink45Deg
                                                                size={25}
                                                                className="cursor-pointer text-blue"
                                                            />
                                                        </div>
                                                        <div className="flex-1 ">
                                                            <input
                                                                type="text"
                                                                placeholder="Event Name"
                                                                className="inputStyle_input"
                                                                value={link}
                                                                defaultValue={link}
                                                            />
                                                        </div>
                                                        <div className="inputStyle_right ">
                                                            <MdContentCopy
                                                                size={25}
                                                                className="inpuStyle_right_icon"
                                                                onClick={click}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="my-3 w-full">
                                                    <div className="inpuStyle">
                                                        <div className="px-2">
                                                            <MdQrCodeScanner
                                                                size={25}
                                                                className="cursor-pointer text-blue"
                                                            />
                                                        </div>
                                                        <div className="flex-1 ">
                                                            <p className="text-start">Download QR code</p>
                                                        </div>
                                                        <div className="inputStyle_right ">
                                                            <FiDownload
                                                                size={25}
                                                                className="inpuStyle_right_icon"
                                                                onClick={() => setdownload(true)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3 flex justify-between w-full mx-auto">
                                                    <Link
                                                        to="/addQuestions"
                                                        className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border ${
                                                            mood === "light"
                                                                ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                                                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                                                        } text-sm font-medium py-2.5 bg-transparent transition-all duration-300 flex justify-center items-center w-[45%]`}
                                                        onClick={() => {
                                                            close()
                                                        }}
                                                    >
                                                        Continue
                                                    </Link>
                                                    <a
                                                        href={preview}
                                                        target="_blank"
                                                        className={`focus:ring-4 focus:outline-none border flex justify-center items-center w-[45%] border-[#4F46E5] ${
                                                            mood === "light"
                                                                ? "text-white hover:!text-white bg-[#4F46E5] hover:bg-[#4F46E5]"
                                                                : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-white"
                                                        } font-medium rounded text-sm inline-flex items-center py-2.5 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                                                        onClick={() => {
                                                            close()
                                                        }}
                                                    >
                                                        Preview
                                                    </a>
                                                </div>
                                                <Link
                                                    to="/events"
                                                    className={`text-black mt-4 back`}
                                                    onClick={() => {
                                                        clear()
                                                        redirect(selectName)
                                                        close()
                                                    }}
                                                >
                                                    Go Home
                                                </Link>
                                            </div>
                                        </>
                                    </>
                                )}

                                {status === "failed" && (
                                    <>
                                        <>
                                            <div className="p-6 text-center flex items-center flex-col">
                                               
                                                <Emoji
                                                    size={60}
                                                    emoji="face-with-open-mouth"
                                                />
                                                <p className="font-[600] font-inter">Failed!</p>
                                                <div className="my-3">
                                                    <p className="text-sm text-[#18181B] py-4 !pt-0">
                                                        Oops! something went wrong <br />
                                                        please try again
                                                    </p>
                                                </div>
                                                <div className="mt-1 flex justify-between w-full mx-auto">
                                                    <Link
                                                        to="/events"
                                                        className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                                                            mood === "light"
                                                                ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                                                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                                                        } text-sm font-medium px-10 py-2 bg-transparent transition-all duration-300`}
                                                        onClick={() => {
                                                            redirect()
                                                            close()
                                                        }}
                                                    >
                                                        Go, Back
                                                    </Link>
                                                    <Link
                                                        to="/addQuestions"
                                                        className={`focus:ring-4 focus:outline-none border flex justify-center items-center w-[45%] border-[#4F46E5] ${
                                                            mood === "light"
                                                                ? "text-white hover:!text-white bg-[#4F46E5] hover:bg-[#4F46E5]"
                                                                : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-white"
                                                        } font-medium rounded text-sm inline-flex items-center py-2.5 text-center hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                                                        onClick={() => {
                                                            redirect(selectName)
                                                            close()
                                                        }}
                                                    >
                                                        Try again
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    </>
                                )}

                                {status === "clear" && (
                                    <>
                                        <>
                                            <div className="p-6 text-center flex items-center flex-col">
                                                
                                                <Emoji
                                                    emoji="face-with-open-mouth"
                                                    size={60}
                                                />

                                                <p className="font-[600] font-inter mt-3">Confirmation!</p>
                                                <p className="text-sm text-[#18181B] pt-2 text-center">
                                                    Are you sure you want to go back?
                                                </p>
                                                <p className="text-sm text-[#18181B] pb-2 text-center">
                                                    Your edited data will loss
                                                </p>
                                                <div className="mt-1 flex justify-between w-[90%] mx-auto pl-2">
                                                    <button
                                                        type="button"
                                                        className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                                                            mood === "light"
                                                                ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                                                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                                                        } text-sm font-medium px-10 py-2 bg-transparent transition-all duration-300`}
                                                        onClick={() => {
                                                            close()
                                                        }}
                                                    >
                                                        No
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-[45%] w-full border border-[lightBlue] ${
                                                            mood === "light"
                                                                ? "text-white hover:!text-white bg-lightBlue hover:bg-lightBlue"
                                                                : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-white"
                                                        } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2 mx-6 hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                                                        onClick={() => {
                                                            redirect(selectName)
                                                            close()
                                                        }}
                                                    >
                                                        Yes
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    </>
                                )} */}

                {status === "updated" && (
                  <>
                    <>
                      <div className="p-6 text-center flex items-center flex-col">
                        <Emoji emoji="grinning-face-with-big-eyes" size={60} />

                        <p className="font-[600] capitalize font-inter mt-4">
                          {message} Updated
                        </p>
                        <div className="my-8 max-w-[200px]">
                          <p className="text-sm text-[#18181B]">
                            Your {message} has been updated successfully.
                          </p>
                        </div>
                        <div className="mt-1 flex justify-between w-[90%] mx-auto pl-2">
                          <button
                            type="button"
                            className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-full w-full border border-[lightBlue] ${
                              mood === "light"
                                ? "text-white bg-lightBlue hover:bg-lightBlue"
                                : " border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-white"
                            } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2  hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-blue border`}
                            onClick={() => {
                              close();
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}

                {status === "Enable" && (
                  <>
                    <div className="p-4 text-center flex items-center flex-col">
                      <Emoji emoji="face-with-raised-eyebrow" size={60} />

                      <p className="font-[600] font-inter mt-4">Enable User</p>
                      <p className="text-sm max-w-[230px] text-[#18181B] mt-8 !pt-0">
                        Are you sure you want to enable this user?
                      </p>

                      <div className="mt-6 flex justify-between w-full mx-auto">
                        <button
                          onClick={close}
                          data-modal-toggle="popup-modal"
                          type="button"
                          className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                            mood === "light"
                              ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                              : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                          } text-sm font-medium px-10 py-2 bg-transparent transition-all duration-300`}
                        >
                          No, Go back
                        </button>
                        {!loader && (
                          <button
                            // data-modal-toggle="popup-modal"
                            type="button"
                            className={` focus:outline-none flex justify-center items-center max-w-[45%] w-full ${
                              mood === "light"
                                ? "text-white  bg-cl"
                                : "text-[#303544] hover:!text-white"
                            } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2  hover:opacity-60`}
                            onClick={() => {
                              handelActivateUser(id);
                            }}
                          >
                            Yes, Enable
                          </button>
                        )}
                        {loader && (
                          <button
                            // data-modal-toggle="popup-modal"
                            type="button"
                            className={` focus:outline-none flex justify-center items-center max-w-[45%] w-full ${
                              mood === "light"
                                ? "text-white  bg-cl"
                                : "text-[#303544] hover:!text-white"
                            } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2  hover:opacity-60`}
                            onClick={() => {
                              handelActivateUser(id);
                            }}
                          >
                            <PuffLoader size={30} color="#FFFFFF" />
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {status === "Disable" && (
                  <>
                    <div className="p-4 text-center flex items-center flex-col">
                      <Emoji emoji="face-with-raised-eyebrow" size={60} />

                      <p className="font-[600] font-inter mt-4">Dnable User</p>
                      <p className="text-sm max-w-[230px] text-[#18181B] mt-8 !pt-0">
                        Are you sure you want to dnable this user?
                      </p>

                      <div className="mt-6 flex justify-between w-full mx-auto">
                        <button
                          onClick={close}
                          data-modal-toggle="popup-modal"
                          type="button"
                          className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                            mood === "light"
                              ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                              : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                          } text-sm font-medium px-10 py-2 bg-transparent transition-all duration-300`}
                        >
                          No, Go back
                        </button>

                        {!loader && (
                          <button
                            // data-modal-toggle="popup-modal"
                            type="button"
                            className={` focus:outline-none flex justify-center items-center max-w-[45%] w-full ${
                              mood === "light"
                                ? "text-white  bg-cl"
                                : "text-[#303544] hover:!text-white"
                            } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2  hover:opacity-60`}
                            onClick={() => {
                              handelDeactivateUser(id);
                            }}
                          >
                            Yes, Disable
                          </button>
                        )}
                        {loader && (
                          <button
                            // data-modal-toggle="popup-modal"
                            type="button"
                            className={` focus:outline-none flex justify-center items-center max-w-[45%] w-full ${
                              mood === "light"
                                ? "text-white  bg-cl"
                                : "text-[#303544] hover:!text-white"
                            } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2  hover:opacity-60`}
                            onClick={() => {
                              handelDeactivateUser(id);
                            }}
                          >
                            <PuffLoader size={30} color="#FFFFFF" />
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* {status === "delete" && (
                                    <>
                                        <>
                                            <div className="p-4 text-center flex items-center flex-col">
                                             
                                                <Emoji
                                                    emoji="astonished-face"
                                                    size={60}
                                                />

                                                <p className="font-[600] font-inter mt-3"> Delete Campaign</p>
                                                <p className="text-sm text-[#18181B] py-4 !pt-0">
                                                    Are you sure you want to delete this <br />
                                                    <span className="text-blue"> “{eventNames}”</span> Campaign?
                                                </p>

                                                <div className="mt-1 flex justify-between w-full mx-auto">
                                                    <button
                                                        onClick={close}
                                                        data-modal-toggle="popup-modal"
                                                        type="button"
                                                        className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                                                            mood === "light"
                                                                ? "border-gray hover:!bg-gray hover:text-white text-[#8a8989]"
                                                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                                                        } text-sm font-medium px-7 py-2 bg-transparent transition-all duration-300`}
                                                    >
                                                        No, Go back
                                                    </button>
                                                    <button
                                                        data-modal-toggle="popup-modal"
                                                        type="button"
                                                        className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-[45%] w-full border border-[lightBlue] ${
                                                            mood === "light"
                                                                ? "text-white hover:!text-white bg-red hover:bg-red"
                                                                : "bg-transperent border-[#303544] text-[#303544] hover:bg-[#232734] hover:!border-[#232734] hover:!text-white"
                                                        } font-medium rounded text-sm inline-flex items-center px-7 py-2 text-center mr-2 hover:bg-transparent transition-all duration-300 hover:text-blue dark:hover:border-red border`}
                                                        onClick={() => {
                                                            deleteEvent(id)
                                                        }}
                                                    >
                                                        Yes Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    </>
                                )} */}

                {/* {status === "Deleted" && (
                                    <>
                                        <>
                                            <div className="p-4 text-center flex items-center flex-col">
                                           
                                                <Emoji
                                                    emoji="astonished-face"
                                                    size={60}
                                                />

                                                <p className="font-[600] font-inter mt-3"> Campaign has been removed</p>

                                                <div className="mt-4 flex justify-center w-full mx-auto">
                                                    <button
                                                        onClick={() => {
                                                            close()
                                                            window.location.replace("https://www.feedbacksync.com/")
                                                        }}
                                                        data-modal-toggle="popup-modal"
                                                        type="button"
                                                        className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] ${
                                                            mood === "light"
                                                                ? "border-red hover:!bg-red hover:text-white text-white"
                                                                : "bg-[#303544] border-[#303544] text-white hover:bg-[#232734]"
                                                        } text-sm font-medium px-7 py-2 bg-red transition-all duration-300`}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    </>
                                )} */}
                {status === "logout" && (
                  <>
                    <>
                      <div className="p-4 text-center flex items-center flex-col">
                        <Emoji emoji="face-with-raised-eyebrow" size={60} />

                        <p className="font-[600] font-inter mt-4">
                          {" "}
                          Confirmation
                        </p>
                        <p className="text-sm text-[#18181B] mt-6 !pt-0">
                          Are you sure you want to logout <br />
                          from the feedback sync?
                        </p>

                        <div className="mt-8 flex justify-between w-full mx-auto">
                          <button
                            onClick={close}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className={`hover:bg-transparent focus:ring-4 focus:outline-none rounded border flex justify-center items-center w-full max-w-[45%] border-gray hover:!bg-gray hover:text-white text-[#8a8989]
                                                        } text-sm font-medium px-7 py-2 bg-transparent transition-all duration-300`}
                          >
                            No, Go back
                          </button>
                          <button
                            onClick={clearAll}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className={`focus:ring-4 focus:outline-none flex justify-center items-center max-w-[45%] w-full border  text-white hover:!text-white bg-red
                                                                font-medium rounded text-sm px-7 py-2 text-center mr-2  transition-all duration-300  `}
                          >
                            Yes, Logout
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {/* {download && (
                <div className="hidden">
                    <QRcode
                        value={preview}
                        click={"download"}
                    />
                </div>
            )} */}
    </div>
  );
};

export default EventName;
