/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axios";
import toast, { Toaster } from "react-hot-toast";
import logo from "../../assets/logo/logo1.svg";
import { PuffLoader } from "react-spinners";
import FadeIn from "react-fade-in/lib/FadeIn";

const SAdminSignIn = () => {
  const [visible, setVisible] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  if (isChecked)
    localStorage.setItem(
      "admin_remember",
      JSON.stringify({
        Email: inputValues.email,
        Password: inputValues.password,
      })
    );

  const Remember = JSON.parse(localStorage.getItem("admin_remember"));

  useEffect(() => {
    if (Remember !== null) {
      if (Remember?.email?.length > 0) {
        setInputValues({
          email: Remember?.email?.length === 0 ? "" : Remember?.email,
          password: Remember?.password?.length === 0 ? "" : Remember?.password,
        });
      }
    }
  }, []);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setInputValues((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const handelSubmit = async () => {
    if (inputValues.email.trim().length > 0) {
      setLoader(true);
      const { data } = await axios.post("/login-superAdmin", {
        email: inputValues.email,
        password: inputValues.password,
      });
      if (data.success) {
        if (data?.token) localStorage.setItem("Admin_Token", data.token);
        navigate("/");
        return setLoader(false);
      }
    } else {
      return toast.error("Please Enter Email");
    }
  };

  return (
    <div className="max-h-[100%] overflow-hidden py-20">
      <div className="fixed bottom-0 left-0 w-full border-b-[10px] border-[#4F46E5] z-50"></div>
      <div
        style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)" }}
        className="py-5 w-full !z-50 absolute top-0"
      >
        <a href="https://www.feedbacksync.com" alt="feedback">
          <img className="w-[175px] block mx-auto" src={logo} alt="" />
        </a>
      </div>
      <FadeIn>
        <section className="h-full !mt-[80px] w-full">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative max-w-md mx-auto lg:max-w-lg">
              <div className="absolute -inset-2">
                {/* <div
                className="w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter"
                style={{"background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%"}}
              ></div> */}
              </div>

              <>
                <div
                  style={{
                    boxShadow: "0px 0px 61.3082px rgba(0, 0, 0, 0.15)",
                  }}
                  className="bg-[white] relative overflow-hidden rounded-[16px]"
                >
                  <div className="px-4 py-6 sm:px-8">
                    <h1
                      className="font-bold font-pj text-center text-[#18181B] text-[21px] hover:cursor-pointer"
                      // onClick={() => setMagic(true)}
                    >
                      Login to your account
                    </h1>

                    <div className="space-y-5 mt-[30px]">
                      <div>
                        <label
                          for=""
                          className="text-[14px] text-[#18181B] font-semibold text-gray-900 font-pj"
                        >
                          {" "}
                          Email{" "}
                        </label>
                        <div className="mt-1">
                          <input
                            required
                            type="email"
                            name="email"
                            placeholder="example@gmail.com"
                            className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-[#A1A1AA] rounded-[10px] focus:border-gray-900 focus:ring-gray-900 caret-gray-900"
                            value={inputValues.email}
                            onChange={handelChange}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          for=""
                          className="text-[15px] text-[#18181B] font-semibold text-gray-900 font-pj"
                        >
                          {" "}
                          Password{" "}
                        </label>
                        <div className="mt-1 flex items-center relative">
                          <input
                            required
                            type={visible === false ? "password" : "text"}
                            name="password"
                            placeholder="Password (min. 8 character)"
                            className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-[#A1A1AA] rounded-[10px] focus:border-gray-900 focus:ring-gray-900 caret-gray-900"
                            value={inputValues.password}
                            onChange={handelChange}
                          />
                          <p className=" absolute z-50 right-3 ">
                            {visible === false ? (
                              <>
                                <AiFillEyeInvisible
                                  size={26}
                                  color="gray"
                                  className="cursor-pointer"
                                  onClick={() => setVisible(true)}
                                />
                              </>
                            ) : (
                              <>
                                <AiFillEye
                                  size={26}
                                  color="gray"
                                  className="cursor-pointer"
                                  onClick={() => setVisible(false)}
                                />
                              </>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="relative items-center mt-4 flex justify-between">
                        <div className="flex items-center h-5">
                          <input
                            type="checkbox"
                            name="terms"
                            id="terms"
                            value={isChecked}
                            onChange={(e) => {
                              setIsChecked(e.target.checked);
                            }}
                            className="w-5 h-5 text-gray-900 border-gray-300 rounded focus:ring-gray-900"
                          />
                          <div className="ml-3 text-base">
                            <label
                              for="terms"
                              className="text-[#18181B] font-[16px] font-pj"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!loader ? (
                      <button
                        type="submit"
                        className="flex items-center justify-center w-full px-8 h-[50px] max-h-[50px] mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-white hover:text-[#4F46E5] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray  hover:bg-grayDark"
                        onClick={handelSubmit}
                      >
                        Log in
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={`flex items-center justify-center overflow-hidden h-[50px] max-h-[50px] w-full px-8 mt-5 text-base font-bold text-white transition-all duration-200 bg-[#4F46E5] border border-transparent hover:border-[#4F46E5] hover:!bg-[#4e46e5e8] hover:text-[white] rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray`}
                      >
                        <PuffLoader size={60} color="white" className="" />
                      </button>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </section>
      </FadeIn>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default SAdminSignIn;
