import { Suspense, useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserValue } from "../redux/slices/auth";
import axios from "../axios";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("Admin_Token");
  const dispatch = useDispatch();
  const [status, setstatus] = useState(false);
  const [searchParams] = useSearchParams();
  const Admin = searchParams.get("admin");

  useEffect(() => {
    if (Admin !== null) {
      setstatus(true);
    }
  }, []);

  const getUser = async () => {
    try {
      if (userToken !== null) {
        const { data } = await axios.get("/superAdmin-Details", {
          headers: {
            Authorization: userToken,
          },
        });
        if (data.length === 0) {
          navigate("/login");
        } else {
          dispatch(setUserValue(data.data[0]));
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  };

  useEffect(() => {
    getUser();
  }, [status]);

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};

export default PrivateRoute;
