/* eslint-disable  */
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { BsChevronDown } from "react-icons/bs";
import "flatpickr/dist/themes/material_green.css";
import DateFilterMenu from "./../DateFilterMenu";
import axios from "../../axios";
import { AiOutlineSearch } from "react-icons/ai";

const QRTemplateChart = () => {
  const [data, setData] = useState([]);
  const [label, setlabel] = useState("today");
  const [categories, setcategories] = useState([]);
  const [dateType, setDateType] = useState("today");
  const [filterOpen, setFilterOpen] = useState(false);
  const [getData, setgetData] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [eventID, setEventID] = useState("");
  const [search, setSearch] = useState("");
  const [eventList, seteventList] = useState([]);
  const [filterValue, setfilterValue] = useState([]);
  const [filterOpen2, setFilterOpen2] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("All Campaigns");



  useEffect(() => {
    const getAllEvent = async () => {
      const { data } = await axios.get("/getallCampings");

      return seteventList(data.data);
    };
    getAllEvent();
  }, []);

  useEffect(() => {
    const result =
      search.length === 0
        ? eventList
        : eventList.filter((item) =>
            item.eventName.toLowerCase().includes(search.toLowerCase())
          );
    setfilterValue(result);
  }, [search, eventList]);

  useEffect(() => {
    const getData = async () => {
      if (dateType === "dateRange") {
        if (dateRange !== null) {
          const { data } = await axios.post("/qrtemplate-chart", {
            label: dateType,
            fromDate: dateRange[0],
            toDate: dateRange[1],
            eventID: eventID,
          });

          setData(data);
          setgetData(false);
          setDateRange(null);
        }
      } else {
        const { data } = await axios.post("/qrtemplate-chart", {
          label: dateType,
          eventID: eventID,
        });
        setlabel(
          (dateType === "dateRange" && "dateRange") ||
            (dateType === "today" && "today") ||
            (dateType === "yesterday" && "today") ||
            (dateType === "thisMonth" && "month") ||
            (dateType === "lastMonth" && "month") ||
            (dateType === "thisWeek" && "week") ||
            (dateType === "lastWeek" && "week") ||
            (dateType === "thisYear" && "year") ||
            (dateType === "lastYear" && "year")
        );

        setData(data);
        setgetData(false);
      }
    };
    getData();
  }, [getData, dateType, dateRange]);

  useEffect(() => {
    switch (label) {
      case "month":
        setcategories(Array.from({ length: 31 }, (_, i) => (i + 1).toString()));
        break;

      case "week":
        setcategories([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ]);
        break;
      case "year":
        setcategories([
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]);
        break;
      case "today":
        setcategories([
          "12AM",
          "1AM",
          "2AM",
          "3AM",
          "4AM",
          "5AM",
          "6AM",
          "7AM",
          "8AM",
          "9AM",
          "10AM",
          "11AM",
          "12PM",
          "1PM",
          "2PM",
          "3PM",
          "4PM",
          "5PM",
          "6PM",
          "7PM",
          "8PM",
          "9PM",
          "10PM",
          "11PM",
        ]);
        break;
    }
  }, [label]);

  // ** Chart Options
  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      zoom: "disable",
    },
    fill: {
      colors: "#4F46E5",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: [2],
        borderRadiusApplication: "end",
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      // type: "datetime",
      labels: {
        style: {
          colors: "rgba(152, 150, 150, 1)",
          fontSize: "14px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
   
    noData: {
      text: "Loading...",
    },
  };

  // ** Chart Series
  const series = [
    {
      name: "User",
      data: data,
    },
  ];


  return (
    <div>
      <div
        style={{ boxShadow: "0px 2.37037px 8px rgba(0, 0, 0, 0.06)" }}
        className="border border-[#E4E4E7] max-h-[500px] h-[500px] overflow-hidden bg-white rounded-xl"
      >
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-[20px] font-semibold text-[#737373] lg:order-1">
              QR Template
              </p>
            </div>
            <div className="flex items-center space-x-2">
            <div className="mt-4 sm:mt-0">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      onClick={() => {
                        setFilterOpen(false);
                        setFilterOpen2((prev) => !prev);
                      }}
                      type="button"
                      className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {selectedEvent}
                      <BsChevronDown size={16} />
                    </button>
                  </div>

                  <div
                    className={` ${
                      filterOpen2 ? "visible" : "hidden"
                    } absolute p-4 right-0 z-10 mt-2 w-[300px] h-[400px] pb-10 overflow-hidden origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div className="flex items-center justify-between  cursor-pointer">
                      <label
                        for="feedback-checkbox-all"
                        className="ml-2 w-full text-base select-none font-medium text-[#436073] cursor-pointer dark:text-gray-300"
                        onClick={() => {
                            setEventID("");
                            setSelectedEvent("All Campaigns");
                            setFilterOpen2(false);
                          }}
                     >
                        All Campaigns
                      </label>
                    </div>

                    <hr className=" my-4 text-gray" />

                    <div className="w-full rounded-lg  border-[#E4E4E7] border-2 flex items-center overflow-hidden">
                      <input
                        value={search}
                        type="search"
                        placeholder="Search"
                        className="outline-0 border-0 flex-1 py-2 rounded-lg px-2 placeholder:text-[#4F46E5]"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <AiOutlineSearch
                        color="#4F46E5"
                        className="w-6 h-6 mx-2 "
                      />
                    </div>

                    {/* <p className="my-4 text-sm text-gray">
                      {search.length ? "Search Result" : ""}
                    </p> */}
                      <ul className="flex flex-col gap-4 w-[100%] h-[75%] mt-5 overflow-auto bg-white">
                        {filterValue.map((i, index) => {
                          return (
                            <li
                              key={index}
                              className="flex items-center justify-between  cursor-pointer"
                            >
                              <label
                                for={`feedback-checkbox-item-${index}`}
                                className="ml-2 w-full text-base select-none font-medium text-[#436073] cursor-pointer"
                                onClick={() => {
                                  setEventID(i.shortID);
                                  setSelectedEvent(i.eventName);
                                  setFilterOpen2(false);
                                }}
                              >
                                {i.eventName}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                  </div>
                </div>
              </div>

              <div className="mt-4 sm:mt-0">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      onClick={() => setFilterOpen((prev) => !prev)}
                      type="button"
                      className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {dateType}
                      <BsChevronDown size={16} />
                    </button>
                  </div>
                  {filterOpen && (
                    <DateFilterMenu
                      filterOpen={filterOpen}
                      close={setFilterOpen}
                      setDateTypes={setDateType}
                      dateRanges={setDateRange}
                      getData={setgetData}
                    />
                  )}
                </div>
              </div>
              
            </div>
          </div>
          <Chart
            options={options}
            series={series}
            type="bar"
            width={500}
            height={400}
          />
        </div>
      </div>
    </div>
  );
};

export default QRTemplateChart;
