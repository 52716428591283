import React, { useEffect, useState } from "react";
import { VscFeedback, VscCreditCard } from "react-icons/vsc";
import ReactCount from "react-countup";
import axios from "../../axios";
import { HiOutlineUsers } from "react-icons/hi";
import { HiOutlineArchiveBox } from "react-icons/hi2";

const Box = () => {
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalFeedback, setTotalFeedback] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalCampings, setTotalCampings] = useState(0);
  const [liveCampings, setLiveCampings] = useState(0);

  useEffect(() => {
    const Api = async () => {
      const { data } = await axios.get("/totalPayment");
      setTotalPayment(data.count);

      const feedbackCount = await axios.get("/feedbackCount");
      setTotalFeedback(feedbackCount.data.count);

      const userCount = await axios.get("/totalUser");
      setTotalUser(userCount.data.count);

      const totalCamping = await axios.get("/totalCampings");
      setTotalCampings(totalCamping.data.count);

      const liveCamping = await axios.get("/liveCampings");
      setLiveCampings(liveCamping.data.count);
    };
    Api();
  }, []);

  return (
    <div>
      <div className="py-12 bg-gray-50 w-full sm:py-16 lg:py-10">
        <div className="w-full">
          <div className="w-full grid grid-cols-1 gap-10 mx-auto sm:grid-cols-4 lg:grid-cols-6">
            <div
              style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
              className="border-[#E2E8F0] border bg-white rounded-xl  py-3 flex items-center"
            >
              <div className="px-5 py-4 w-full flex flex-col items-center">
                <div className=" grid justify-center items-center bg-lightColor w-12 h-12 rounded-full">
                  <span>
                    <VscCreditCard className=" text-blue" size={25} />
                  </span>
                </div>
                <div className="flex flex-col items-center justify-between mt-6">
                  <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                    Total Payment
                  </p>
                  <ReactCount
                    prefix="₹ "
                    end={totalPayment}
                    duration={2}
                    className="text-[20px] font-bold text-blue mt-2"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
              className="border-[#E2E8F0] border bg-white rounded-xl  py-3 flex items-center"
            >
              <div className="px-5 py-4 w-full flex flex-col items-center">
                <div className=" grid justify-center items-center bg-lightColor w-12 h-12 rounded-full">
                  <span>
                    <VscFeedback className=" text-blue" size={25} />
                  </span>
                </div>
                <div className="flex flex-col items-center justify-between mt-6">
                  <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                    Total Feedback
                  </p>
                  <ReactCount
                    end={totalFeedback}
                    duration={2}
                    className="text-[20px] font-bold text-blue mt-2"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
              className="border-[#E2E8F0] border bg-white rounded-xl py-3 flex items-center"
            >
              <div className="px-5 py-4 w-full flex flex-col items-center">
                <div className=" grid justify-center items-center bg-lightColor w-12 h-12 rounded-full">
                  <span>
                    <HiOutlineUsers className=" text-blue" size={25} />
                  </span>
                </div>
                <div className="flex flex-col items-center justify-between mt-6">
                  <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                    Total Users
                  </p>
                  <ReactCount
                    end={totalUser}
                    duration={2}
                    className="text-[20px] font-bold text-blue mt-2"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
              className="border-[#E2E8F0] border bg-white rounded-xl  py-3 flex items-center"
            >
              <div className="px-5 py-4 w-full flex flex-col items-center">
                <div className=" grid justify-center items-center bg-lightColor w-12 h-12 rounded-full">
                  <span>
                    <HiOutlineUsers className=" text-blue" size={25} />
                  </span>
                </div>
                <div className="flex flex-col items-center justify-between mt-6">
                  <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                    Current Users
                  </p>
                  <ReactCount
                    end={totalUser}
                    duration={2}
                    className="text-[20px] font-bold text-blue mt-2"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
              className="border-[#E2E8F0] border bg-white rounded-xl  py-3 flex items-center"
            >
              <div className="px-5 py-4 w-full flex flex-col items-center">
                <div className=" grid justify-center items-center bg-lightColor w-12 h-12 rounded-full">
                  <span>
                    <HiOutlineArchiveBox className=" text-blue" size={25} />
                  </span>
                </div>
                <div className="flex flex-col items-center justify-between mt-6">
                  <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                    Total Campaign
                  </p>
                  <ReactCount
                    end={totalCampings}
                    duration={2}
                    className="text-[20px] font-bold text-blue mt-2"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ boxShadow: "0px 0px 24px rgba(79, 70, 229, 0.08)" }}
              className="border-[#E2E8F0] border bg-white rounded-xl py-3 flex items-center"
            >
              <div className="px-5 py-4 w-full flex flex-col items-center">
                <div className=" grid justify-center items-center bg-lightColor w-12 h-12 rounded-full">
                  <span>
                    <HiOutlineArchiveBox className=" text-blue" size={25} />
                  </span>
                </div>
                <div className="flex flex-col items-center justify-between mt-6">
                  <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                    Current Campaign
                  </p>
                  <ReactCount
                    end={liveCampings}
                    duration={2}
                    className="text-[20px] font-bold text-blue mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box;
