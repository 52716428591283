import React, { useState } from "react"

import EventName from "./../../components/popUp/EventName"

const Notification = () => {
    const [showPopup, setShowPopup] = useState(false)

    return (
        <div class="flow-root mt-8 max-w-6xl mx-auto pb-10">
            <div class="-my-5 divide-y divide-gray">
                <div class="py-5">
                    <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                        <div class="flex items-center flex-1 min-w-0">
                            <div class="flex-1 min-w-0">
                                <p class=" text-base font-bold text-gray-900 truncate">Manage Notifications</p>
                                <p class="mt-1 text-xs font-normal text-[#52525B] truncate">
                                    You can enable the notifications that you wanna receive.
                                </p>
                            </div>
                        </div>

                        <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                            <a
                                href="#"
                                title=""
                                class="text-sm font-medium text-[#52525B] transition-all duration-200 hover:text-gray-900"
                            >
                                {" "}
                                Learn More{" "}
                            </a>

                            <label class="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                />
                                <div class="w-11 h-6 bg-white border border-gray peer-focus:outline-0  peer-focus:ring-blue rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-blue after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray peer-checked:after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="py-5">
                    <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                        <div class="flex items-center flex-1 min-w-0">
                            <div class="flex-1 min-w-0">
                                <p class=" text-base font-bold text-gray-900 truncate">Payment</p>
                                <p class="mt-1 text-xs font-normal text-[#52525B] truncate">
                                    Lorem ipsum dolor sit amet, consectes.{" "}
                                </p>
                            </div>
                        </div>

                        <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                            <a
                                href="#"
                                title=""
                                class="text-sm font-medium text-[#52525B] transition-all duration-200 hover:text-gray-900"
                            >
                                {" "}
                                Learn More{" "}
                            </a>

                            <label class="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                />
                                <div class="w-11 h-6 bg-white border border-gray peer-focus:outline-0  peer-focus:ring-blue rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-blue after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray peer-checked:after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="py-5">
                    <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                        <div class="flex items-center flex-1 min-w-0">
                            <div class="flex-1 min-w-0">
                                <p class=" text-base font-bold text-gray-900 truncate">Promotion</p>
                                <p class="mt-1 text-xs font-normal text-[#52525B] truncate">
                                    Lorem ipsum dolor sit amet, consectes.
                                </p>
                            </div>
                        </div>

                        <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                            <a
                                href="#"
                                title=""
                                class="text-sm font-medium text-[#52525B] transition-all duration-200 hover:text-gray-900"
                            >
                                {" "}
                                Learn More{" "}
                            </a>

                            <label class="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                />
                                <div class="w-11 h-6 bg-white border border-gray peer-focus:outline-0  peer-focus:ring-blue rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-blue after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray peer-checked:after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="py-5">
                    <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                        <div class="flex items-center flex-1 min-w-0">
                            <div class="flex-1 min-w-0">
                                <p class=" text-base font-bold text-gray-900 truncate">Updates</p>
                                <p class="mt-1 text-xs font-normal text-[#52525B] truncate">
                                    Lorem ipsum dolor sit amet, consectetur adipis.
                                </p>
                            </div>
                        </div>

                        <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                            <a
                                href="#"
                                title=""
                                class="text-sm font-medium text-[#52525B] transition-all duration-200 hover:text-gray-900"
                            >
                                {" "}
                                Learn More{" "}
                            </a>

                            <label class="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                />
                                <div class="w-11 h-6 bg-white border border-gray peer-focus:outline-0  peer-focus:ring-blue rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-blue after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray peer-checked:after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="py-5">
                    <div class="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                        <div class="flex items-center flex-1 min-w-0">
                            <div class="flex-1 min-w-0">
                                <p class=" text-base font-bold text-gray-900 truncate">Security Alerts</p>
                                <p class="mt-1 text-xs font-normal text-[#52525B] truncate">
                                    Lorem ipsum dolor sit amet adipis.
                                </p>
                            </div>
                        </div>

                        <div class="flex items-center justify-between mt-4 sm:space-x-6 pl-14 sm:pl-0 sm:justify-end sm:mt-0">
                            <a
                                href="#"
                                title=""
                                class="text-sm font-medium text-[#52525B] transition-all duration-200 hover:text-gray-900"
                            >
                                {" "}
                                Learn More{" "}
                            </a>

                            <label class="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                />
                                <div class="w-11 h-6 bg-white border border-gray peer-focus:outline-0  peer-focus:ring-blue rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-blue after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gray peer-checked:after:bg-white after:border-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue"></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 sm:mt-12 flex justify-start mb-3">
                <button
                    type="submit"
                    onClick={() => setShowPopup(true)}
                    className="active:scale-95 hover:scale-[1.02] inline-flex items-center justify-center px-10 py-3 text-sm font-semibold 
            leading-5 text-white transition-all duration-200 bg-[#4F46E6] border border-transparent rounded-md focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-igray-700 hover:bg-[#4e46e6c0]"
                >
                    Save
                </button>
            </div>
            {showPopup && (
                <EventName
                    status="updated"
                    show={showPopup}
                    hide={setShowPopup}
                    message="notification"
                />
            )}
        </div>
    )
}

export default Notification
