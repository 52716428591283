import { useRoutes } from "react-router-dom";

// ** Route Components
import PublicRoute from "../security/PublicRoute";
import PrivateRoute from "../security/PrivateRoute";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import Account from "../pages/Account/index";
import UserManagement from "../pages/UserManagement";
import Payment from "../pages/Payment";

const Router = () => {
  const route = [
    {
      element: <PrivateRoute />,
      children: [
        { path: "/", element: <Dashboard /> },
        { path: "/account", element: <Account /> },
        { path: "/user-management", element: <UserManagement /> },
        { path: "/payment", element: <Payment /> },
      ],
    },
    {
      element: <PublicRoute />,
      children: [{ path: "/login", element: <Login /> }],
    },
  ];

  const routes = useRoutes(route);

  return routes;
};

export default Router;
