import React from "react"
import FadeIn from "react-fade-in/lib/FadeIn"
// import { useSelector } from "react-redux";
import Box from "./Box"
import UserChart from "./UserChart"
import CampaignChart from "./CampaignChart"
import QRTemplateChart from "./QRTemplateChart"
import PaymentChart from "./PaymentsChart"
import FeedbacksChart from "./FeedbacksChart"
import EmailChart from "./EmailChart"

const DashboardComponents = () => {
    // const { bgColor } = useSelector((state) => state.bg);
    // const { mood } = useSelector((state) => state.mood);

    return (
        <div className="flex-1 overflow-x-hidden">
            <main>
                <div className="py-6">
                    <FadeIn>
                        <div className=" mx-auto px-12">
                            <Box />
                            <div className="flex items-center gap-10 w-full">
                                <UserChart />
                                <div className="flex-1">
                                    <PaymentChart />
                                </div>
                            </div>
                            <div className="flex items-center w-full gap-10 mt-10">
                                <CampaignChart />
                                <div className="flex-1">
                                    <FeedbacksChart />
                                </div>
                            </div>
                            <div className="flex items-center w-full gap-10 mt-10">
                                <QRTemplateChart />
                                <div className="flex-1">
                                    <EmailChart />
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            </main>
        </div>
    )
}

export default DashboardComponents
