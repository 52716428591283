import React, { useState } from "react";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import {
  BsBuilding,
  BsCalendarEvent,
  BsClipboard,
  BsJournalBookmark,
  BsTelephone,
  BsQrCode,
} from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import ReactCount from "react-countup";
import { VscCreditCard, VscFeedback } from "react-icons/vsc";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import axios from "../../axios";
import { useEffect } from "react";

const Popup = ({ show, hide, id }) => {
  const [information, setInformation] = useState([]);

  const close = () => {
    return hide(false);
  };

  useEffect(() => {
    const API = async () => {
      if (id !== undefined) {
        const { data } = await axios.post("/getUser", {
          id,
        });

        if (data.success) {
          setInformation(data.data);
          return;
        }
      }
    };
    API();
  }, [id]);

  return (
    <div>
      {show && (
        <>
          <div
            id="popup-modal"
            tabindex="-1"
            className=" fixed top-5 h-full w-screen bg-black/20 flex flex-1 justify-center items-center "
            aria-hidden="true"
            onClick={() => hide(true)}
          >
            <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
              <div
                style={{ boxShadow: "2px 2px 10px #ddd" }}
                className="relative bg-white rounded-lg h-full max-h-[68vh] overflow-y-scroll no-scrollbar"
              >
                {/* close button */}
                <button
                  type="button"
                  className="absolute top-1 right-2 text-red bg-transparent hover:bg-gray hover:text-red rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:bg-gray dark:hover:text-white transition-all duration-300"
                  data-modal-toggle="popup-modal"
                  onClick={close}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>

                {/* body */}
                <div className="p-10 flex items-center flex-col">
                  <h2 className=" text-xl font-medium text-blue">
                    User Information
                  </h2>
                  <div className=" flex flex-col divide-y-2 divide-gray mt-8 w-full">
                    <div className="flex gap-7 py-4">
                      <div className="flex justify-between w-1/2">
                        <p className="flex gap-2 items-center text-[#64748B] ">
                          {" "}
                          <AiOutlineUser /> Name
                        </p>
                        <span>:</span>
                      </div>
                      <div className=" w-1/2 text-base font-medium">
                        {information.name}
                      </div>
                    </div>
                    <div className="flex gap-7 py-4">
                      <div className="flex justify-between w-1/2">
                        <p className="flex gap-2 items-center text-[#64748B] ">
                          {" "}
                          <AiOutlineMail /> Email Address
                        </p>
                        <span>:</span>
                      </div>
                      <div className=" w-1/2 text-base font-medium">
                        {information.email}
                      </div>
                    </div>
                    <div className="flex gap-7 py-4">
                      <div className="flex justify-between w-1/2">
                        <p className="flex gap-2 items-center text-[#64748B] ">
                          {" "}
                          <BsTelephone /> Phone Number
                        </p>
                        <span>:</span>
                      </div>
                      <div className=" w-1/2 text-base font-medium">
                        {information.phone_no}
                      </div>
                    </div>
                    <div className="flex gap-7 py-4">
                      <div className="flex justify-between w-1/2">
                        <p className="flex gap-2 items-center text-[#64748B] ">
                          {" "}
                          <BsBuilding /> Company Name
                        </p>
                        <span>:</span>
                      </div>
                      <div className=" w-1/2 text-base font-medium">
                        {information.company_name}
                      </div>
                    </div>
                    <div className="flex gap-7 py-4">
                      <div className="flex justify-between w-1/2">
                        <p className="flex gap-2 items-center text-[#64748B] ">
                          {" "}
                          <BsCalendarEvent /> Joined Date
                        </p>
                        <span>:</span>
                      </div>
                      <div className=" w-1/2 text-base font-medium">
                        {new Date(information.createdAt).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="flex gap-7 py-4">
                      <div className="flex justify-between w-1/2">
                        <p className="flex gap-2 items-center text-[#64748B] ">
                          {" "}
                          <BsClipboard /> Plan
                        </p>
                        <span>:</span>
                      </div>
                      <div className=" w-1/2 text-base font-medium">
                        Premium
                      </div>
                    </div>
                    <div className="flex gap-7 py-4">
                      <div className="flex justify-between w-1/2">
                        <p className="flex gap-2 items-center text-[#64748B] ">
                          {" "}
                          <BsJournalBookmark /> Subscription
                        </p>
                        <span>:</span>
                      </div>
                      <div className=" w-1/2 text-base font-medium">Yearly</div>
                    </div>
                  </div>

                  {/* cards */}
                  <div className=" w-full grid grid-cols-3 gap-10 mt-8">
                    {/* payments */}
                    <div className="px-5 py-4 w-full flex flex-col items-center border border-gray rounded-xl">
                      <div className=" grid justify-center items-center bg-lightColor  w-12 h-12 rounded-full">
                        <span>
                          <VscCreditCard className=" text-blue" size={25} />
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-between mt-6">
                        <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                          Total Payment
                        </p>
                        <ReactCount
                          end={information.totalPatmeny}
                          duration={2}
                          className="text-[20px] font-bold text-blue mt-2"
                        />
                      </div>
                    </div>
                    {/* totalFeedback */}
                    <div className="px-5 py-4 w-full flex flex-col items-center border border-gray rounded-xl">
                      <div className=" grid justify-center items-center bg-lightColor  w-12 h-12 rounded-full">
                        <span>
                          <VscFeedback className=" text-blue" size={25} />
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-between mt-6">
                        <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                          Total Feedback
                        </p>
                        <ReactCount
                          end={information.totalFeedback}
                          duration={2}
                          className="text-[20px] font-bold text-blue mt-2"
                        />
                      </div>
                    </div>
                    {/* totalCampings */}
                    <div className="px-5 py-4 w-full flex flex-col items-center border border-gray rounded-xl">
                      <div className=" grid justify-center items-center bg-lightColor  w-12 h-12 rounded-full">
                        <span>
                          <HiOutlineArchiveBox
                            className=" text-blue"
                            size={25}
                          />
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-between mt-6">
                        <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                          Total Campaign
                        </p>
                        <ReactCount
                          end={information.totalCampings}
                          duration={2}
                          className="text-[20px] font-bold text-blue mt-2"
                        />
                      </div>
                    </div>
                    {/* qrcodeTemplate */}
                    <div className="px-5 py-4 w-full flex flex-col items-center border border-gray rounded-xl">
                      <div className=" grid justify-center items-center bg-lightColor  w-12 h-12 rounded-full">
                        <span>
                          <BsQrCode className=" text-blue" size={25} />
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-between mt-6">
                        <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                          Total QR Template
                        </p>
                        <ReactCount
                          end={information.totalqrCode}
                          duration={2}
                          className="text-[20px] font-bold text-blue mt-2"
                        />
                      </div>
                    </div>

                    {/* totalEMail */}
                    <div className="px-5 py-4 w-full flex flex-col items-center border border-gray rounded-xl">
                      <div className=" grid justify-center items-center bg-lightColor  w-12 h-12 rounded-full">
                        <span>
                          <AiOutlineMail className=" text-blue" size={25} />
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-between mt-6">
                        <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                          Total Email
                        </p>
                        <ReactCount
                          end={information.totalEmail}
                          duration={2}
                          className="text-[20px] font-bold text-blue mt-2"
                        />
                      </div>
                    </div>
                    {/* totalSMS */}
                    <div className="px-5 py-4 w-full flex flex-col items-center border border-gray rounded-xl">
                      <div className=" grid justify-center items-center bg-lightColor  w-12 h-12 rounded-full">
                        <span>
                          <IoChatboxEllipsesOutline
                            className=" text-blue"
                            size={25}
                          />
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-between mt-6">
                        <p className=" text-base text-center font-medium tracking-wider text-grayDark capitalize">
                          Total SMS
                        </p>
                        <ReactCount
                          end={information.totalSMS}
                          duration={2}
                          className="text-[20px] font-bold text-blue mt-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Popup;
