/* eslint-disable  */
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { BsChevronDown } from "react-icons/bs";
import "flatpickr/dist/themes/material_green.css";
import DateFilterMenu from "./../DateFilterMenu";
import axios from "../../axios";

const UserChart = () => {
  const [data, setData] = useState([]);
  const [label, setlabel] = useState("today");
  const [categories, setcategories] = useState([]);
  const [dateType, setDateType] = useState("today");
  const [filterOpen, setFilterOpen] = useState(false);
  const [getData, setgetData] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    const getData = async () => {
      if (dateType === "dateRange") {
        if (dateRange !== null) {
          const { data } = await axios.post("/usercountChart", {
            label: dateType,
            fromDate: dateRange[0],
            toDate: dateRange[1],
          });

          setData(data);
          setgetData(false);
          setDateRange(null);
        }
      } else {
        const { data } = await axios.post("/usercountChart", {
          label: dateType,
        });
        setlabel(
          (dateType === "dateRange" && "dateRange") ||
            (dateType === "today" && "today") ||
            (dateType === "yesterday" && "today") ||
            (dateType === "thisMonth" && "month") ||
            (dateType === "lastMonth" && "month") ||
            (dateType === "thisWeek" && "week") ||
            (dateType === "lastWeek" && "week") ||
            (dateType === "thisYear" && "year") ||
            (dateType === "lastYear" && "year")
        );

        setData(data);
        setgetData(false);
      }
    };
    getData();
  }, [getData, dateType, dateRange]);

  useEffect(() => {
    switch (label) {
      case "month":
        setcategories(Array.from({ length: 31 }, (_, i) => (i + 1).toString()));
        break;

      case "week":
        setcategories([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ]);
        break;
      case "year":
        setcategories([
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]);
        break;
      case "today":
        setcategories([
          "12AM",
          "1AM",
          "2AM",
          "3AM",
          "4AM",
          "5AM",
          "6AM",
          "7AM",
          "8AM",
          "9AM",
          "10AM",
          "11AM",
          "12PM",
          "1PM",
          "2PM",
          "3PM",
          "4PM",
          "5PM",
          "6PM",
          "7PM",
          "8PM",
          "9PM",
          "10PM",
          "11PM",
        ]);
        break;
    }
  }, [label]);

  // ** Chart Options
  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      zoom: "disable",
    },
    fill: {
      colors: "#4F46E5",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: [2],
        borderRadiusApplication: "end",
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      // type: "datetime",
      labels: {
        style: {
          colors: "rgba(152, 150, 150, 1)",
          fontSize: "14px",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
   
    noData: {
      text: "Loading...",
    },
  };

  // ** Chart Series
  const series = [
    {
      name: "User",
      data: data,
    },
  ];


  return (
    <div>
      <div
        style={{ boxShadow: "0px 2.37037px 8px rgba(0, 0, 0, 0.06)" }}
        className="border border-[#E4E4E7] max-h-[500px] h-[500px] overflow-hidden bg-white rounded-xl"
      >
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-[20px] font-semibold text-[#737373] lg:order-1">
                Users
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <div className="mt-4 sm:mt-0">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      onClick={() => setFilterOpen((prev) => !prev)}
                      type="button"
                      className="inline-flex w-full justify-center items-center gap-x-10 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {dateType}
                      <BsChevronDown size={16} />
                    </button>
                  </div>
                  {filterOpen && (
                    <DateFilterMenu
                      filterOpen={filterOpen}
                      close={setFilterOpen}
                      setDateTypes={setDateType}
                      dateRanges={setDateRange}
                      getData={setgetData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Chart
            options={options}
            series={series}
            type="bar"
            width={500}
            height={400}
          />
        </div>
      </div>
    </div>
  );
};

export default UserChart;
